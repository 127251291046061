import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './forms/home/home.component';
import { NotfoundComponent } from './forms/notfound/notfound.component';
import { EmergencyPhoneComponent } from './forms/emergency-phone/emergency-phone.component';
import { PortabilityComponent } from './forms/portability/portability.component';
import { RenewComponent } from './forms/renew/renew.component';
import { ContractRenewComponent } from './forms/contract-renew/contract-renew.component';
import { ScheduleComponent } from './forms/schedule/schedule.component';
import { CustomFormComponent } from './forms/custom-form/custom-form.component';
import { FlowSignupComponent } from './forms/flow-signup/flow-signup.component';
import { BfAfterPayComponent } from './forms/bf-after-pay/bf-after-pay.component';

const routes: Routes = [
  { path: 'renew/:id', component: RenewComponent },
  { path: 'contract/renew/:id', component: ContractRenewComponent },
  { path: 'portability/:id', component: PortabilityComponent },
  { path: 'emergency-phone/:id', component: EmergencyPhoneComponent },
  { path: 'schedule/:id', component: ScheduleComponent },
  { path: 'forms/:id', component: CustomFormComponent },
  { path: 'flow/signup/:id_customer', component: FlowSignupComponent },
  { path: 'bf_after_pay/:id_customer', component: BfAfterPayComponent },
  
  { path: '', component: HomeComponent },
  { path: '**', component: NotfoundComponent }  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }