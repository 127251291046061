import { Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { DateUtil } from '../lib/DateUtil';
import { CustomFormService } from 'src/app/services/custom-form.service';
import CodeUtil from '../lib/code.util';

@Component({
    selector: 'oss-uvalues',
    templateUrl: './uvalues.component.html',
    styleUrls: ['./uvalues.component.css']
})
export class UvaluesComponent implements OnInit {
    @ContentChild('inputTemplate')
    inputTemplateRef?: TemplateRef<any>;

    private _fields: any[] = [];
    private _values: any;

    @Input() target: string = '';

    @Input() viewMode: 'vertical' | 'horizontal' = 'vertical';

    @Input()
    get fields() { return this._fields; }
    set fields(values: any[]) {
        this._fields = CodeUtil.parseString(values);
    }


    @Input()
    get values() { return this._values; }
    set values(values: any) {
        this._values = CodeUtil.parseString(values);
    };


    @Input() readonly: boolean = false;

    public d_values: any = {};

    @Output() refresh: EventEmitter<any> = new EventEmitter();

    constructor(private customFormService: CustomFormService) { }

    public async ngOnInit(): Promise<void> {
        for (let field of this.fields) {
            if (field.fieldtype == 'DATE') {
                this.d_values[field.fieldname] = DateUtil.fromDb(this.values[field.fieldname]);
            }

            if (field.fieldtype == 'TABLE') {
                await this.loadTables(field);
                this.loadUFields(field.fieldname);
            }
            
            if (field.options) {
                this.d_values[field.fieldname] = !this.values[field.fieldname] ? null : this.values[field.fieldname];
            }
        }

        (<any>window)['getVar'] = (name: string) => {
            return this.d_values[name] || this.values[name];
        }
    }

    private async loadTables(field: any): Promise<void> {
        try {
            if (field.param_tablename) {
                field.tables = [];
                field.tables = await this.customFormService.getUtablesByMasterTable(field.param_tablename).toPromise();
            }
        } catch (err) { }
    }

    private async loadUFields(fieldname: string, newValues: boolean = false): Promise<void> {
        try {
            if (this.values[fieldname] != 'null') {

                if (newValues) {
                    this.clearUFields(fieldname);
                }

                const ufields = await this.customFormService.getUfieldsByTable(this.values[fieldname]).toPromise();

                for (let ufield of ufields) {
                    ufield.fieldname = fieldname.concat(':' + ufield.fieldname);
                    ufield.section = this.fields.find((f) => f.fieldname === fieldname)?.label;


                    if (CodeUtil.isEmpty(this.values[ufield.fieldname])) {
                        this.values[ufield.fieldname] = null;
                        newValues = true;
                    }

                }

                this.fields = this.fields.concat(ufields);

                // this.fields.sort((a, b) => {
                //     console.log(a.ordre.concat(a.fieldname), b.ordre.concat(b.fieldname))

                //     if (a.fieldname.split(':')[0] === b.fieldname.split(':')[0] ) {
                //         return a.fieldname.localeCompare(b.fieldname);
                //     }

                //     return a.ordre - b.ordre
                // });


                if (newValues) {
                    this.onRefresh();
                }

            } else {
                this.clearUFields(fieldname);
                this.onRefresh();
            }

        } catch (err) { }
    }

    private clearUFields(fieldname: string): void {
        let toDelete: any = {};
        for (let i = 0, n = this.fields.length; i < n; i += 1) {
            let f = this.fields[i];
            if (f.fieldname.split(':').length > 1 && f.fieldname.split(':')[0] == fieldname) {
                toDelete[i] = f.fieldname;
            }
        }

        for (let inx of Object.keys(toDelete)) {
            this.fields.splice(Number(inx), 1);
            delete this.values[toDelete[inx]];
        }
    }

    onChange(field: any) {
        if (field.fieldtype == 'TABLE') {
            this.loadUFields(field.fieldname, true);
        } else {
            if (field.fieldtype == 'DATE') {
                this.values[field.fieldname] = DateUtil.toDb(this.d_values[field.fieldname]);
            }

            if (field.fieldtype == 'OPTIONS') {
                this.values[field.fieldname] = this.d_values[field.fieldname];
            }

            this.onRefresh();
        }
    }

    public evalCondition(field: any): boolean {
        let condition = eval(field.condition || true);

        if (!condition && field) {
            if (this.values[field.fieldname]) {
                this.values[field.fieldname] = undefined;
                this.d_values[field.fieldname] = undefined;
                this.onRefresh();
            }
        }

        return condition;
    }

    private onRefresh(): void {
        this.refresh.emit({ fields: this.fields, values: this.values, target: this.target });
    }

}
