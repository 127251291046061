import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class FlowSignupService  extends BaseService {

  constructor(public http: HttpClient) {
      super('/api', http);
  }

  public getFlowsignup(id: string): Observable<any> {
      return this.doGet(this.REST_URL + '/customer/flowsignup/' + id);
  }

}
