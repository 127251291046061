import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CustomFormService extends BaseService {

    constructor(public http: HttpClient) {
        super('/api/customer/form', http);
    }

    public getCustomForm(id_form: string): Observable<any> {
        return this.doGet(`${this.REST_URL}/${id_form}`);
    }

    public getUtablesByMasterTable(mastertable: string): Observable<any> {
        return this.doGet(`${this.REST_URL}/mastertable/${mastertable}`);
    }

    public getUfieldsByTable(table: string): Observable<any> {
        return this.doGet(`${this.REST_URL}/ufields/${table}`);
    }

    public submitForm(form: any): Observable<any> {
        return this.doPost(`${this.REST_URL}`, form);
    }

}
