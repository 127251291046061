export class DateUtil {

    public static toDb(data: Date): string | null {

        if (!data) return null;

        let dd = data.getDate();
        let mm = data.getMonth() + 1; //January is 0!
        let yyyy = data.getFullYear();

        let ret = dd.toString();

        if (dd < 10) {
            ret = '0' + ret;
        }

        ret = mm + "-" + ret;

        if (mm < 10) {
            ret = '0' + ret;
        }

        ret = yyyy + '-' + ret;

        return ret;
    }

    public static fromDb(sData: string): Date | null {
        if (sData) {
            let d: any[] = sData.split("T")[0].split("-");
            let r = new Date(d[0], d[1] - 1, d[2]);

            return r;
        } else {
            return null;
        }
    }

    public static getDateFormatApp(sData: string, locale: string = 'es'): string | null {
        if (sData) {
            let d: any[] = sData.split("T")[0].split("-");
            let r = new Date(d[0], d[1] - 1, d[2]);

            return r.toLocaleString(locale, {year: 'numeric', month: 'numeric', day: 'numeric'});
        } else {
            return null;
        }
        
    }
}

