import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { RenewComponent } from './forms/renew/renew.component';
import { HomeComponent } from './forms/home/home.component';
import { NotfoundComponent } from './forms/notfound/notfound.component';
import { PortabilityComponent } from './forms/portability/portability.component';
import { EmergencyPhoneComponent } from './forms/emergency-phone/emergency-phone.component';
import { ContractRenewComponent } from './forms/contract-renew/contract-renew.component';
import { ScheduleComponent } from './forms/schedule/schedule.component';
import { OssWeb1Component } from './components/oss-web1/oss-web1.component';
import { UvaluesComponent } from './shared/uvalues/uvalues.component';
import { OssField1Component } from './shared/oss-field1/oss-field1.component';
import { CustomFormComponent } from './forms/custom-form/custom-form.component';
import { OssField2Component } from './shared/oss-field2/oss-field2.component';
import { FlowSignupComponent } from './forms/flow-signup/flow-signup.component';
import { BfAfterPayComponent } from './forms/bf-after-pay/bf-after-pay.component';
import { OssWeb2Component } from './components/oss-web2/oss-web2.component';

// Prime
import { CalendarModule } from 'primeng/calendar';
import { StepsModule } from 'primeng/steps';
import { ButtonModule } from 'primeng/button';
import { InputNumberModule } from 'primeng/inputnumber';
import { RadioButtonModule } from 'primeng/radiobutton';

const PrimengModule = [
    CalendarModule,
    StepsModule,
    ButtonModule,
    InputNumberModule,
    RadioButtonModule
];

// Calendar
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';


FullCalendarModule.registerPlugins([
    dayGridPlugin, interactionPlugin
])

@NgModule({
    declarations: [
        AppComponent,
        RenewComponent,
        HomeComponent,
        NotfoundComponent,
        PortabilityComponent,
        EmergencyPhoneComponent,
        ContractRenewComponent,
        ScheduleComponent,
        OssWeb1Component, OssWeb2Component,
        UvaluesComponent,
        CustomFormComponent,
        OssField1Component, OssField2Component,
        FlowSignupComponent,
        BfAfterPayComponent
    ],
    imports: [
        BrowserModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        AppRoutingModule,
        FullCalendarModule,
        PrimengModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }
