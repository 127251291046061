import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest } from 'rxjs/internal/observable/combineLatest';
import { ContractRenewService } from 'src/app/services/contract-renew.service';
import { RenewService } from 'src/app/services/renew.service';

@Component({
    selector: 'app-contract-renew',
    templateUrl: './contract-renew.component.html',
    styleUrls: ['./contract-renew.component.css']
})
export class ContractRenewComponent implements OnInit {
    public id: string = '';
    public idProduct?: string;
    public message: string = "loading...";
    public renewInfo: any;

    constructor(
        public activeRoute: ActivatedRoute,
        public contractRenewService: ContractRenewService
    ) { }

    public async ngOnInit(): Promise<void> {
        combineLatest([this.activeRoute.params, this.activeRoute.queryParams]).subscribe(async ([params, queryParams]) => {
            if (this.idProduct) {
                this.idProduct = params.id_product;
            }
            
            if (this.id != params['id']) {
                this.id = params['id'];
                this.load();
            }
        });
    }

    private async load(): Promise<void> {
        const info = await this.contractRenewService.getContractRenew(this.id).toPromise();
        if (info.customer_fullname) {
            this.renewInfo = info;
            this.renewInfo.addons = info.products.filter((p: any) => p.product_type == 'ADDON');
            this.renewInfo.products = info.products.filter((p: any) => p.product_type == 'PRODUCT');
            this.renewInfo.fibreService = info.products.find((p: any) => p.code == 'FIBRE');
            if (info.products.length > 0) {
                const data = { products: info.products.map((p: any) => p.id_product), promotions: info.promotions.map((p: any) => p.id_promotion) };
                this.renewInfo.monthlyPayment = await this.contractRenewService.calculateMonthlyPayment(data).toPromise();
            }
            this.message = "";
        }
    }

    renew() {
        this.message = "loading...";

        this.contractRenewService.renew(this.id, this.renewInfo, (res, error) => {

            if (error) {
                this.message = "Internal error";
            } else {
                this.message = "Thank you! Your contract has been renewed.";
            }
        });


    }

}
