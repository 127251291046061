import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';

@Injectable({
    providedIn: 'root'
})
export class RenewService extends BaseService {

    constructor(public http: HttpClient) {
        super('/api', http);
    }

    public renew(id: string): Observable<any> {
        return this.doPost(this.REST_URL + '/customer/renew/', { id });
    }

    public getRenew(id: string): Observable<any> {
        return this.doGet(this.REST_URL + '/customer/renew/' + id);
    }

}
