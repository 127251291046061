import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';

@Injectable({
    providedIn: 'root'
})
export class PortabilityService extends BaseService {

    constructor(public http: HttpClient) {
        super('/api/customer/portability', http);
    }

    public getPortability(id_workorder: string): Observable<any> {
        return this.doGet(`${this.REST_URL}/${id_workorder}`);
    }

    public submitPortability(id_workorder: string, data: any): Observable<any> {
        return this.doPost(`${this.REST_URL}/${id_workorder}`, data);
    }

}