import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FlowSignupService } from 'src/app/services/flow-signup.service';

@Component({
  selector: 'app-flow-signup',
  templateUrl: './flow-signup.component.html',
  styleUrls: ['./flow-signup.component.css']
})
export class FlowSignupComponent implements OnInit {

  message:string="Loading...";
  id_customer:string="";
  
  info:any;

  constructor(public activeRoute: ActivatedRoute, public flowSignupService:FlowSignupService) { }

  async ngOnInit(): Promise<void> {
    this.id_customer = this.activeRoute.snapshot.params['id_customer'];

    this.info = await this.flowSignupService.getFlowsignup(this.id_customer).toPromise();  

    if (this.info.next_url) window.location.href = this.info.next_url; 
    
    this.message = ""; //JSON.stringify(flowInfo);
  }

}
