import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ParamService } from 'src/app/services/param.service';
import { ScheduleService } from 'src/app/services/schedule.service';
import { DateUtil } from 'src/app/shared/lib/DateUtil';

@Component({
    selector: 'app-schedule',
    templateUrl: './schedule.component.html',
    styleUrls: ['./schedule.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class ScheduleComponent implements OnInit {
    public id: string = '';
    public message: string = ""

    public can_reschedule = false;

    public info: any = null;

    public selectedDay: any;

    public currentMonth: string="";
    public currentYear:string="";
    public days: any[] = [];

    constructor(
        public activeRoute: ActivatedRoute,
        public scheduleService: ScheduleService,
        private paramService: ParamService
    ) {
        this.initializeElements();
    }

    private async initializeElements(): Promise<void> {
        try {
            let d = new Date();

            this.currentMonth = this.getMonthName(d);
            this.currentYear = "" + d.getFullYear();
    
            let dates:Date[] = [];
            let date = new Date();

            const param = await this.paramService.getParam('WORKORDER', 'SCHEDULE_DAYS').toPromise();
            const days = param.value ? Number(param.value) : 30;
    
            for (let i=0; i < days; i++) {
                dates.push(new Date(date));
                date.setDate(date.getDate() + 1);
            }
    
            for (let date of dates) {            
                this.days.push({ day: date.toISOString().substring(0, 10), number: date.toISOString().substring(0, 10).split("-")[2], dayName: this.getDayName(date) });
            }
        } catch (err) {
            throw err;
        }
    }


    ngOnInit(): void {
        this.id = this.activeRoute.snapshot.params['id'];

        this.loadSchedule();

        setTimeout(() => {
            setTimeout(() => window.dispatchEvent(new Event('resize')), 1)
        }, 0);
    }

    private async loadSchedule(): Promise<void> {
        try {
            this.message = "loading...";
            this.info = await this.scheduleService.getSchedule(this.id).toPromise();

            this.can_reschedule = (this.info.provider + "").substring(0,9) != 'FULLFIBRE';

            if (this.info.scheduling_date) {
                this.info.scheduling_date = DateUtil.fromDb(this.info.scheduling_date);
            }

            for (let slot of this.info.allDays) {
                slot.disabled = true;
                if (this.info.calendar.some((c: any) => c.day == slot.day && c.time == slot.time)) {
                    slot.disabled = false;
                }
            }

            this.days = this.days.filter((day) =>  this.info.allDays.map((d: any) => d.day).includes(day.day));
            let firstDay = this.days.length > 0 ? new Date(this.days[0].day) : new Date();
            this.currentMonth = this.getMonthName( firstDay );
            this.currentYear = "" + firstDay.getFullYear();

            this.message = "";
        } catch (err) {
            this.message = "Internal error";
        }
    }

    public async confirm(): Promise<void> {
        this.message = "loading...";

        this.scheduleService.setSchedule(this.id, this.selectedDay, async (res, error) => {
            this.message = "";

            if (error) {
                this.message = "Internal error";
            } else {
                try {
                    await this.loadSchedule();
                    this.message = "";
                } catch (err) {
                    this.message = "Internal error";
                }

            }
        });

    }

    public onSelectSlot() {
        if (this.selectedDay) {
            this.selectedDay.date = DateUtil.fromDb(this.selectedDay.day);
        }
    }

    public getDaysInMonth(month: number, year: number): Date[] {
        let date = new Date(year, month, 1);
        let days = [];
        while (date.getMonth() === month) {
            days.push(new Date(date));
            date.setDate(date.getDate() + 1);
        }
        return days;
    }

    public getDayName(date: Date, locale = 'en', long = false): string {
        return date.toLocaleDateString(locale, { weekday: !long ? 'short' : 'long' });
    }

    public getMonthName(date?: Date): string {
        const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        const d = date || new Date();
        return monthNames[d.getMonth()];
    }

    public onScroll(ev:any) {
        let center_day = Math.floor((document.getElementById("days")?.clientWidth || 1000)  / 250);
        let day = this.days[Math.floor(ev.srcElement.scrollLeft/125)+center_day];

        let d = new Date(day.day);

        this.currentMonth = this.getMonthName(d);
        this.currentYear = "" + d.getFullYear();
        console.log(window.innerWidth);
    }

}
