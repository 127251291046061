import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';

@Injectable({
    providedIn: 'root'
})
export class ScheduleService extends BaseService {

    constructor(public http: HttpClient) {
        super('/api', http);
    }

    public getSchedule(id: string): Observable<any> {
        return this.doGet(this.REST_URL + '/customer/schedule/' + id);
    }

    public setSchedule(id: string, info: any, callback: (res: any, error: any) => any) {
        return this.doPut(this.REST_URL + '/customer/schedule/' + id, info, callback);
    }

}
