class CodeUtil {

    public static isEmpty(obj: any): boolean {
        if (typeof obj == 'string') {
            return obj === '';
        } else {
            return JSON.stringify(obj) === JSON.stringify({});
        }
    }

    public static isNil(obj: any): boolean {
        return obj === null || obj === undefined;
    }

    public static isEqual(obj1: any, obj2: any): boolean {
        return JSON.stringify(obj1) === JSON.stringify(obj2);
    }

    public static omitBy(obj: any, check: any): any {
        obj = { ...obj }
        Object.entries(obj).forEach(([key, value]) => check(value) && delete obj[key])
        return obj
    }

    public static round(num: number, precision: number): number {
        const modifier = 10 ** precision;
        return Math.round(num * modifier) / modifier;
    }

    public static remove(array: any[], iteratee: any): any {
        const toRemove: any[] = [];
        const result = array.filter((item, i) => iteratee(item) && toRemove.push(i));
        toRemove.reverse().forEach(i => array.splice(i, 1));
        return result;
    }

    public static getProp(object: any, keys: any, defaultVal?: string): any {
        keys = Array.isArray(keys) ? keys : keys.split('.');
        object = object[keys[0]];
        if (object && keys.length > 1) {
            return this.getProp(object, keys.slice(1), defaultVal);
        }
        return object !== undefined ? object : defaultVal;
    }

    /*!
    * Group items from an array together by some criteria or value.
    * @param  {Array}           arr      The array to group items from
    * @param  {String|Function} criteria The criteria to group by
    * @return {Object}                   The grouped object
    */
    public static groupBy(arr: any[], criteria: string | Function): any {
        return arr.reduce((obj: any, item: any) => {

            // Check if the criteria is a function to run on the item or a property of it
            var key = typeof criteria === 'function' ? criteria(item) : item[criteria];

            // If the key doesn't exist yet, create it
            if (!obj.hasOwnProperty(key)) {
                obj[key] = [];
            }

            // Push the value to the object
            obj[key].push(item);

            // Return the object to the next item in the loop
            return obj;

        }, {});
    };

    public static trim(str: string, c = '\\s'): string {
        return str?.replace(new RegExp(`^([${c}]*)(.*?)([${c}]*)$`), '$2');
    }

    /**
     * 
     * JSON.parse(JSON.stringify(obj))
     */
    public static parseString(obj: any): any {
        return JSON.parse(JSON.stringify(obj));
    }

    public static s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    };

    public static newId(table: string) {
        return (
            table.substring(0, 8) +
            '-' + CodeUtil.s4() +
            '-' + CodeUtil.s4() +
            '-' + CodeUtil.s4() +
            '-' + CodeUtil.s4() +
            CodeUtil.s4() + CodeUtil.s4()
        ).substring(0, 36);
    }

}

export default CodeUtil;