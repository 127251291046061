import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(public activeRoute: ActivatedRoute) { }

  id_customer:string="";

  ngOnInit(): void {
    this.id_customer = this.activeRoute.snapshot.params['id_customer'];
  }

}
