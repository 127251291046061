import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BfAfterPayService } from 'src/app/services/bf-after-pay.service';

@Component({
  selector: 'app-bf-after-pay',
  templateUrl: './bf-after-pay.component.html',
  styleUrls: ['./bf-after-pay.component.css']
})
export class BfAfterPayComponent implements OnInit {

  message:string="Loading...";
  id_customer:string="";
  
  info:any;

  constructor(public activeRoute: ActivatedRoute, public bfAfterPayService:BfAfterPayService) { }

  async ngOnInit(): Promise<void> {
    this.id_customer = this.activeRoute.snapshot.params['id_customer'];

    this.info = await this.bfAfterPayService.get(this.id_customer).toPromise();  

    if (this.info.url) {
      window.location.href = this.info.url; 
    }
  }

}
