import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { emergencyPhoneService } from 'src/app/services/phone-emergency.service';

@Component({
    selector: 'app-emergency-phone',
    templateUrl: './emergency-phone.component.html',
    styleUrls: ['./emergency-phone.component.css']
})
export class EmergencyPhoneComponent implements OnInit {
    public id: string = '';

    public loading: boolean = true;

    public error: boolean = false;
    public welcome: boolean = false;
    public emergencyPhoneOk: boolean = false;
    public emergencyPhoneInfo: any;
    public emergencyPhoneCheck: boolean = true;

    public message:any="";

    constructor(
        public activeRoute: ActivatedRoute,
        public emergencyPhoneService: emergencyPhoneService
    ) {}

    public async ngOnInit(): Promise<void> {
        try {
            this.loading = true;
            this.id = this.activeRoute.snapshot.params['id'];
            this.emergencyPhoneInfo = await this.emergencyPhoneService.getEmergencyPhoneInfo(this.id).toPromise();
            if (this.emergencyPhoneInfo.emergency_phone) {
                this.welcome = false;
                this.emergencyPhoneOk = true;
            } else {
                this.welcome = true;
            }
            this.loading = false;
        } catch(err) {
            this.error = true;
        }
    }

    public async onSubmit(value: boolean): Promise<void> {
        this.loading = true;
        this.welcome = false;
        try {
            await this.emergencyPhoneService.updateEmergencyPhone(this.id, {emergency_phone: value}).toPromise();
            this.loading = false;
            this.emergencyPhoneOk = true;
        } catch (err) {
            this.error = true;
            throw err;
        }
    }

}
