import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'oss-field1',
    templateUrl: './oss-field1.component.html',
    styleUrls: ['./oss-field1.component.css']
})
export class OssField1Component implements OnInit {
    @Input() customClass: any;
    @Input() label: string = '';
    @Input() cols: string = 'col-sm-10';

    constructor() { }

    ngOnInit(): void {
    }

}
