import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

@Injectable()
export class BaseService {

	public httpOptions: any = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json'
		}),
		responseType: 'json',
		withCredentials: true
	};

	constructor(@Inject(String) public REST_URL: string, public http: HttpClient) {
		this.REST_URL = REST_URL;
	}

	public showError(error: any) {

		let body;
		try {
			body = JSON.parse(error._body);
		} catch (e) {
			body = error._body;
		}

		if (body && body.validacions) {
			alert(body.validacions.map((v: any) => v.txt).join('\n'));
		} else {
			alert(error.statusText);
		}
	}

	public doQuery(url: string, querystring: any): Observable<any> {

		url += '?';

		for (const key of Object.keys(querystring)) {
			url += key + '=' + encodeURIComponent(querystring[key]) + '&';
		}

		return this.doGet(url);
	}

	public doGet(url: string): Observable<any> {
		return this.http.get(url, this.httpOptions);
	}

	public doPost(url: string, data: any): Observable<any>{
        return this.http.post(url, data, this.httpOptions);
    }


	public doPostEx(url: string, data: any, httpOptions: any, callback: (res: any, error: any) => any) {
		return this.http.post(url, data, httpOptions).subscribe(value => {
			callback(value, null);
		}, error => {
			callback(null, error);
		});
	}

	public doPut(url: string, data: any, callback: (res: any, error: any) => any) {
		return this.http.put(url, data, this.httpOptions).subscribe(value => {
			callback(value, null);
		}, error => {
			callback(null, error);
		});
	}

	public doDelete(url: string, callback: (res: any, error: any) => any) {
		return this.http.delete(url, this.httpOptions).subscribe(value => {
			callback(value, null);
		}, error => {
			callback(null, error);
		});
	}

}
