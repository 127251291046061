import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RenewService } from 'src/app/services/renew.service';

@Component({
    selector: 'app-renew',
    templateUrl: './renew.component.html',
    styleUrls: ['./renew.component.css']
})
export class RenewComponent implements OnInit {
    public id: string = '';

    public loading: boolean = true;

    public error: boolean = false;
    public welcome: boolean = false;
    public renewOk: boolean = false;

    public renewInfo: any;

    constructor(
        public activeRoute: ActivatedRoute,
        public renewService: RenewService
    ) { }

    ngOnInit(): void {
        this.id = this.activeRoute.snapshot.params['id'];

        this.renewService.getRenew(this.id).subscribe(value => {
            this.renewInfo = value;
            this.renewInfo.fibreService = this.renewInfo.products.find((p: any) => p.code == 'FIBRE');

            if (this.renewInfo.status != null) this.renewOk = true;
            if (!this.renewOk) this.welcome = true;
            
            this.loading = false;
        });

    }

    public async renew(): Promise<void> {
        this.loading = true;
        this.welcome = false;

        try {
            await this.renewService.renew(this.id).toPromise();
            this.loading = false;
            this.renewOk = true;
    }  catch (err) {
            this.error = true;
            throw err;
        }
    }

}
