import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

    constructor(public activatedRoute: ActivatedRoute, public router: Router) {
    }

    ngOnInit(): void {
    }

}
