import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';

@Injectable({
	providedIn: 'root'
})
export class ContractRenewService extends BaseService {

	constructor(public http: HttpClient) {
		super('/api', http);
	}

	public getContractRenew(id: string): Observable<any> {
		return this.doGet(this.REST_URL + '/customer/contract_renew/' + id);
	}

	public calculateMonthlyPayment(body: any): Observable<any> {
		return this.doPost(this.REST_URL + '/customer/contract_renew/calculate-monthly-payment', body);
	}

	public renew(id: string, renewInfo: any, callback: (res: any, error: any) => any) {
		return this.doPut(this.REST_URL + '/customer/contract_renew/' + id, renewInfo, callback);
	}

}
