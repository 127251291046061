import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomFormService } from 'src/app/services/custom-form.service';
import CodeUtil from 'src/app/shared/lib/code.util';

@Component({
    selector: 'app-custom-form',
    templateUrl: './custom-form.component.html'
})
export class CustomFormComponent implements OnInit {
    public idForm: string = '';
    public form: any;
    public message: string = "loading...";

    constructor(
        public activatedRoute: ActivatedRoute,
        private customFormService: CustomFormService
    ) { }

    public async ngOnInit(): Promise<void> {
        try {
            this.idForm = this.activatedRoute.snapshot.params['id'];
            this.form = await this.customFormService.getCustomForm(this.idForm).toPromise();
            
            if (this.form.submit_date) {
                this.message = 'Thank you for your feedback.';
            } else {
                this.message = '';
            }
        } catch (err) {
            this.message = 'Error. Please try again.';
        }
    }

    public onRefreshUFields(event: any): void {
        if (event.fields && event.values) {
            this.form.fields = event.fields;
            this.form.uvalues = event.values;
        }
    }

    public isValidForm(): boolean {
        return true;
    }

    public async onSubmit(): Promise<void> {
        this.message = 'loading...';
        try {
            const res = await this.customFormService.submitForm(this.form).toPromise();
            if (this.form.code == 'TICKET_FEEDBACK') {
                if (this.form.uvalues['Q2']) {
                    if (this.form.uvalues['Q2'].toUpperCase() == 'YES.') {
                        this.message = `Our Customer Service Advisor will be in touch in the next 2 working days.`;
                    } else if (this.form.uvalues['Q2'].toUpperCase() == 'NO.') {
                        this.message = `We understand. Thank you for your feedback.`;
                    }
                } else {
                    this.message = 'Thank you for your feedback.'
                }
            } else {
                this.message = 'Thank you for your feedback.'
            }
        } catch (err) {
            this.message = String(err);
            throw err;
        }
    }

}