import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-oss-web2',
    templateUrl: './oss-web2.component.html',
    styleUrls: ['./oss-web2.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class OssWeb2Component implements OnInit {
    @Input() message = ""
    @Input() id!: string;
    
    constructor() { }

    ngOnInit(): void {
    }

}
