import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';

@Injectable({
    providedIn: 'root'
})
export class emergencyPhoneService extends BaseService {

    constructor(public http: HttpClient) {
        super('/api/customer/emergency-phone', http);
    }

    public getEmergencyPhoneInfo(id_customer: string): Observable<any> {
        return this.doGet(`${this.REST_URL}/${id_customer}`);
    }

    public updateEmergencyPhone(id_customer: string, data: any): Observable<any>{
        return this.doPost(`${this.REST_URL}/${id_customer}`, data);
    }

}