import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PortabilityService } from 'src/app/services/portability.service';

@Component({
    selector: 'app-portability',
    templateUrl: './portability.component.html'
})
export class PortabilityComponent implements OnInit {
    public id: string = '';
    public portabilityInfo: any = {};
    public form!: FormGroup;
    public loading: boolean = true;
    public error: boolean = false;
    public portabilityOk: boolean = false;

    constructor(
        private formBuilder: FormBuilder,
        public activeRoute: ActivatedRoute,
        private portabilityService: PortabilityService
    ) {
        this.initializeElements();
    }

    private initializeElements(): void {
        this.form = this.formBuilder.group({
            name: [null, Validators.required],
            address: [null, Validators.required],
            account_number: [null, Validators.required],
            phone_number: [null, Validators.required],
            current_provider: [null, Validators.required],
            line_type: [null, Validators.required],
        });
    }

    public async ngOnInit(): Promise<void> {
        try {
            this.id = this.activeRoute.snapshot.params['id'];
            this.portabilityInfo = await this.portabilityService.getPortability(this.id).toPromise();
            this.loading = false;
            if (this.portabilityInfo.status === 'COMPLETEDPHONEDETAILS') {
                this.portabilityOk = true;
            }
            this.setFormValues();
        } catch(err) {
            this.loading = false;
        }
    }

    private setFormValues(): void {
        for (let input in this.portabilityInfo) {
            if (this.form.controls[input] && this.portabilityInfo[input] !== '') {
                this.form.controls[input].setValue(this.portabilityInfo[input]);
            }
        }
    }

    public async onSubmit(): Promise<void> {
        try {
            await this.portabilityService.submitPortability(this.id, this.form.value).toPromise();
            this.portabilityOk = true;
        } catch (err) {
            this.error = true;
            throw err;
        }
    }

}