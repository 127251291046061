import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'oss-field2',
  templateUrl: './oss-field2.component.html',
  styleUrls: ['./oss-field2.component.css']
})
export class OssField2Component implements OnInit {
  @Input() label: string = '';
  @Input() align: 'start' | 'center' | 'end' = 'start';

  constructor() { }

  ngOnInit(): void { }

}
